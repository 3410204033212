import "./ReportsPage.scss";
import { Button, Form, Input, Select, Table, Typography } from "antd";
import type { SelectProps } from "antd";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import TendencyCard from './OmniChannel/TendencyCard'

import DatePicker from "../datepicker/DatePicker";
import { ReactComponent as IconArrowRight } from "../../assets/icons/arrow-right.svg";
import { useDispatch, useSelector } from "react-redux";
import { Column } from '@ant-design/plots';
import { useEffect, useState } from "react";
import { setSelectedMainMenu, setSelectedSubMenu } from "store/Navigation/NavigationStore";
import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "../../assets/icons/loader-onmiChannel.svg";
import { selectLoading, fetchSpendPacingReport, selectSpendPacingReport } from "../../store/SpendPacingReport/SpendPacingReportStore";
import SpendPacingChart from "./SpendPacingChart";
import { selectWhiteLabel } from "../../store/WhiteLabel/WhiteLabelStore";
import { selectLocations, selectFranchiseLocations } from "../../store/Location/LocationStore";
import { selectUserAccount } from "../../store/Account/AccountStore";
import { UserRole } from "models/User";
import { updateURLParams, getParamValue } from "../../utility/url";

export const SpendPacing = (): JSX.Element => {
    var moment = require('moment'); 
    const user = useSelector(selectUserAccount);
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    const userLocations = useSelector(selectLocations);
    const franchiseLocations = useSelector(selectFranchiseLocations);
    const spendPacingReport = useSelector( selectSpendPacingReport );
    const urlMonth = Number(getParamValue('month') as string);
    const currentUrlMonth = urlMonth > 0 ? urlMonth - 1 : null;
    const currentMonth = moment().month();
    const selectedMonth = currentUrlMonth || moment().month();
    const currentYear =  getParamValue('year') || moment().year();
    const [month, setMonth] = useState(selectedMonth);
    const [year, setYear] = useState(currentYear);
    const [monthIsCurrentMonth, setMonthIsCurrentMonth] = useState(true);
    const [allLocations, setAllLocations] = useState([] as any[]);
    const [selectedLocation, setSelectedLocation] = useState(undefined as undefined | string);
    const [form] = Form.useForm();
    const whiteLabel = useSelector(selectWhiteLabel);
    var nameMonth = new Date(month, month).toLocaleString('en-EN', { month: 'long' })

    useEffect(() => {
        dispatch( setSelectedMainMenu("report") );
        dispatch(setSelectedSubMenu("Spend & pacing"));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(selectedLocation && loading){
          console.log('fetching');
          console.log(selectedLocation);
          const userId = getParamValue('user_id') || undefined;
          dispatch(fetchSpendPacingReport({ monthNumber: currentMonth, year: currentYear, locationId: selectedLocation, userId}))
        }
    },[selectedLocation, loading]);// eslint-disable-line react-hooks/exhaustive-deps


    const nextMonth = ( ) => {
      if ( moment().year(year).month(month).add(1,"months") <= moment().year(currentYear).month(currentMonth) ) {
        const newDate = moment().year(year).month(month).add(1, 'months')
        setMonth( newDate.month() )
        setYear( newDate.year() )
        setMonthIsCurrentMonth( newDate.month() == currentMonth );
        updateURLParams({month:  (newDate.month() + 1) });
        updateURLParams({year:  newDate.year() });
      }
    }

    const previuosMonth = ( ) => {
      if ( month-1 > currentMonth-6) {
        const newDate = moment().year(year).month(month).subtract(1, 'months')
        setMonth( newDate.month() )
        setYear( newDate.year() )
        setMonthIsCurrentMonth( newDate.month() == currentMonth );
        updateURLParams({year: year});
        updateURLParams({month: month});
      }
    }

    const onLocationChange = (value: string) => {
        setSelectedLocation(value);
    };

    useEffect(() => {
      if(selectedLocation){
        const userId = getParamValue('user_id') || undefined;
        dispatch(fetchSpendPacingReport({ monthNumber: month, year: year, locationId: selectedLocation, userId}))
      }
    }, [month, selectedLocation]);

    useEffect(() =>{
      if( user?.type != UserRole.Franchisee && franchiseLocations.length > 0){
            //const staticLocations: any = [];
            const staticLocations: any = [
              {label: 'All locations', value: 'all'}
            ];
            const dynamicLocations = franchiseLocations.map((location) => {return {label: location.name, value: location.id}});

            // Sort only the dynamicLocations array
            dynamicLocations.sort((a, b) => {
              const labelA = a.label.toLowerCase();
              const labelB = b.label.toLowerCase();

              if (labelA < labelB) {
                  return -1;
              }
              if (labelA > labelB) {
                  return 1;
              }
              return 0;
            });

            const allLocationsList = [...staticLocations, ...dynamicLocations];
            setAllLocations(allLocationsList);

            form.setFieldsValue({ location: allLocationsList[0] ? allLocationsList[0].value : null  });
            setSelectedLocation(allLocationsList[0] ? allLocationsList[0].value : undefined)
        }else if(user?.type == UserRole.Franchisee && userLocations.length > 0){
          let staticLocations: any = [];
          if( userLocations.length > 1 ){
            staticLocations = [
              {label: 'All locations', value: 'all'}
            ];
          }else{
            staticLocations = [];
          }
          //const staticLocations: any = [];
          const dynamicLocations = userLocations.map((location) => {return {label: location.name, value: location.id}});

          // Sort only the dynamicLocations array
          dynamicLocations.sort((a, b) => {
            const labelA = a.label.toLowerCase();
            const labelB = b.label.toLowerCase();

            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
          });

          const allLocationsList = [...staticLocations, ...dynamicLocations];
          setAllLocations(allLocationsList);

          form.setFieldsValue({ location: allLocationsList[0] ? allLocationsList[0].value : null  });
          setSelectedLocation(allLocationsList[0] ? allLocationsList[0].value : undefined)

        }
    }, [user, userLocations, franchiseLocations])

    const channelListHtml = spendPacingReport ? spendPacingReport.channelList.map((channel, index) => (
      <div key={channel.channelId} className="flex items-center mb-4">
          <div className="w-3/12">
              <p className="m-0 text-left">{channel.channelName}</p>
          </div>
          <div className="w-7/12">
              <div style={{backgroundColor: whiteLabel.primaryColor, width: Math.ceil(channel.spend * 100 / spendPacingReport.currentSpend)+'%'}} className={`h-3 rounded-sm`}></div>
          </div>
          <div className="w-3/12">
              <p className="m-0 text-right">$ {Number((isNaN(channel.spend) ? 0 : channel.spend).toFixed(2)).toLocaleString('en-US')}</p>
          </div>
      </div>
    )) : undefined;

    return (
      <div className="res:mb-[180px]">
        <div className="container">
        <div className="grid items-start grid-cols-5 res:grid-cols-1 gap-x-7">
            <div className="col-span-2 res:col-span-1 ">
              <div className="flex justify-between">
                <div className="selectMonth h-fit">
                  <div className={`arrowPrev ${ moment().year(year).month(month) <= moment().year(currentYear).month(currentMonth).subtract(5,'months') ? "pointer-events-none " : "" } `} onClick={previuosMonth}>
                    <ArrowIcon className={`res:ml-0 svg-black cursor-pointer  hover:opacity-[1]     rotate-[90deg]  ${ moment().year(year).month(month) > moment().year(currentYear).month(currentMonth).subtract(5,'months') ? "opacity-100" : "opacity-[0.2]" } `} />
                  </div>
                  
                  <div className="text-[15px] capitalize mx-5 w-[80px] text-center"> { nameMonth } </div>

                  <div className={`arrowNext ${ moment().year(year).month(month) >=  moment().year(currentYear).month(currentMonth) ? "pointer-events-none" : "" } `} onClick={nextMonth}>
                    <ArrowIcon className={`res:ml-0 svg-black cursor-pointer  hover:opacity-[1]    rotate-[170deg] ${   moment().year(year).month(month) <  moment().year(currentYear).month(currentMonth) ? "opacity-100" : "opacity-[0.2]" }` }  />
                  </div>
                </div>

                { spendPacingReport && (
                  <div className="ml-auto text-right total">
                    <div className="text-[16px]"> {monthIsCurrentMonth? "Current spend" : "Final spend" } </div>
                    <div className="text-[18px]"> ${Number((isNaN(spendPacingReport.currentSpend) ? 0 : spendPacingReport.currentSpend).toFixed(2)).toLocaleString('en-US')}</div>
                  </div>  
                )}
              </div>

              { allLocations.length > 1 && (
                <div className="flex">
                  <Form
                      form={form}
                      name="basic"
                      autoComplete="off"
                    >
                      <Form.Item
                        className="!ml-0"
                        name="location"
                        style={{  width: "183px" }}
                      >
                        <Select
                          showArrow
                          value={selectedLocation}
                          style={{ borderRadius: "4px", width: "100%" }}
                          onChange={onLocationChange}
                          className="mb-[1px]"
                          options={allLocations}
                        />
                      </Form.Item>
                    </Form>
                </div>
              )}

              

              { spendPacingReport && (
                <>
                  <div className={'col-span-2 dividerCustom w-full my-5'}>
                      <label>Budget</label>
                      <div></div>
                  </div>

                  <div className="grid w-full grid-cols-2 gap-6 res:content-start">
                    <div className="col-span-2 "></div>
                    <TendencyCard 
                        isLandingCard={true}
                        amount={
                            {
                                name: 'Adjusted',
                                amount: Number((isNaN(spendPacingReport.monthlyTotal) ? 0 : spendPacingReport.monthlyTotal).toFixed(2)).toLocaleString('en-US'),
                                suffix: '',
                                prefix: '$',
                                varianceAmount: 0,
                                tooltipTitle: "Your total monthly budget. This amount is distributed among your active channels."
                            }
                        }
                        key={"monthly-total"}
                        haveTooltip={true}
                        titleTooltip={"Lorem Ipsum"}
                    />
{/*                     { 
                      (monthIsCurrentMonth == true) ? (<>
                        <TendencyCard 
                            isLandingCard={true}
                            amount={
                                {
                                    name: 'Daily search',
                                    amount: Number(spendPacingReport.dailySearchBudget).toLocaleString('en-US'),
                                    suffix: '',
                                    prefix: '$',
                                    varianceAmount: 0,
                                    tooltipTitle: "The daily budget set for your search campaigns. This value is used to limit the total search spend over the course of the month."
                                }
                            }
                            key={"daily-search"}
                            haveTooltip={true}
                            titleTooltip={"Lorem Ipsum"}                        
                        />
                        <TendencyCard 
                            isLandingCard={true}
                            amount={
                                {
                                    name: 'Daily social',
                                    amount: Number(spendPacingReport.dailySocialBudget).toLocaleString('en-US'),
                                    suffix: '',
                                    prefix: '$',
                                    varianceAmount: 0,
                                    tooltipTitle: "The daily budget set for your social campaigns. This value is used to limit the total social spend over the course of the month."
                                }
                            }
                            key={"daily-social"}
                            haveTooltip={true}
                            titleTooltip={"Lorem Ipsum"}
                        /> 
                      </>) : <></>
                    } */}
                    
                  </div>

                  <div className={'col-span-2 dividerCustom w-full mt-11 mb-5'}>
                      <label>Spend by channel</label>
                      <div></div>
                  </div>

                  <div className="w-full col-span-2">
                    { channelListHtml }
                  </div>
                </>
              )}


            </div>

            

            { spendPacingReport && (
              <div className="col-span-3 res:col-span-1 ">
                <div className=" bg-white p-4  shadow-[0px_0px_8px_0px_#00000033]  mb-5 rounded-md flex flex-wrap">

                  <div className="flex items-center w-full">
                    <div className="w-2/12">
                        <p className="m-0 font-bold text-left">Target pace</p>
                    </div>
                    <div className={`w-7/12 border border-[var(--color-primary)] rounded-md border-solid`}>
                        <div style={{width: Math.ceil( (monthIsCurrentMonth? moment().date() : moment().daysInMonth()) * 100 / moment().daysInMonth() ) +'%'}} className={`h-3 rounded-[3px] bg-[var(--color-primary)]`}></div>
                    </div>
                    <div className={` w-3/12 pl-5`}>
                      <span>Days left: { monthIsCurrentMonth? moment().daysInMonth() - moment().date(): 0} </span>
                    </div>
                  </div>

                  <div className="flex items-center w-full mt-4">
                    <div className="w-2/12">
                        <p className="m-0 font-bold text-left"> {monthIsCurrentMonth? "Current pace" : "Final pace" }</p>
                    </div>
                    <div className={`w-7/12 border border-[#009051] rounded-md border-solid`}>
                        <div style={{width: Math.ceil( spendPacingReport.currentSpend * 100 / spendPacingReport.monthlyTotal ) +'%'}} className={`h-3 rounded-[3px] bg-[#009051] max-w-full`}></div>
                    </div>
                    <div className={` w-3/12 pl-5`}>
                      <span></span>
                    </div>
                  </div>
                  

                </div>

                <div className="bg-white p-4 rounded-md shadow-[0px_0px_8px_0px_#00000033]">
                  { (spendPacingReport.dateList.length > 0 ) && (
                    <SpendPacingChart dates={spendPacingReport.dateList}/>
                  )}
                </div>
              </div>
            )}

          </div>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <LoaderIcon className="loader-icon" />
          {/* <CircularProgress color="inherit" /> */}
        </Backdrop>
    </div>)
}
