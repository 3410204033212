import { Modal } from "antd";
import { BudgetApproval } from "models/Budget";
import { Dispatch } from "react";
import services from "../../../services";

interface ApproveModalInterface {
    open: boolean;
    closeModal: Dispatch<React.SetStateAction<boolean>>;
    record: BudgetApproval;
}

export const ApproveModal = ({open, closeModal, record}: ApproveModalInterface) => {
    const handleOk = async (recordId?: string) => {
        await services.budgetService.setApprovedToBudget(recordId!)
            .catch(() => alert('Could not change the budget to approved'));

        closeModal(false);
        window.location.reload();
    };

    const budgetValue = record.budget?.toLocaleString('en-US',{maximumFractionDigits: 2});

    return (
    <Modal
        title={<h2>Approving</h2>}
        visible={open}
        onCancel={() => closeModal(false)}
        footer={false}
    >
        <div>
            <h4>{`You are approving a budget of $${budgetValue} for ${record.name}`}</h4>
        </div>
        <div style={{'display': 'flex', 'justifyContent': 'center', 'marginTop': '10px'}}>
            <div
                className="primary general-button pointer-hover"
                onClick={() => handleOk(record.id!)}
            >
                <div className="sidebar-dashboard-button-text text-center">Approve</div>
            </div>
        </div>
    </Modal>);
}